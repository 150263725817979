.App {
  text-align: center;
}

button{
  margin-bottom: 10px;
  width: 200px;
  height: 30px;
  border-radius: 20px;
  
}